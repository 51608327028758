<template>
  <div class="flex-grow-1">
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
        <v-form ref="referees">
          <v-expansion-panels class="mb-7" flat>
            <v-expansion-panel class="shadow-lg">
              <v-expansion-panel-header>
                <div class="d-flex flex-column">
                  <span class="text-h6 primary--text font-weight-black text-capitalize">
                    Professional Referees
                  </span>
                </div>
                <template v-slot:actions>
                  <v-icon color="icon-chev" fab solo>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mb-5"></v-divider>
                <div v-if="proReferees">
                  <div
                    class="d-flex flex-column"
                    v-for="referee in proReferees"
                    :key="referee.id"
                  >
                    <v-row class="justify-space-between pl-4 pr-4">
                      <p class="body-1 font-weight-medium">
                        {{ referee.name }} {{ referee.lastName }}, {{ referee.position }}, {{ referee.organisation}} <br />
                        {{ referee.phone }}, {{ referee.email }}, {{ referee.relation }}
                      </p>
                      <div :ref="`edit-${referee.id}`">
                        <v-hover
                          v-slot="{ hover }"
                        >
                          <v-btn
                          text
                          @click="modifyReferee(referee)"
                          >
                            <v-icon
                            size="25"
                            :class="{ 'on-hover success--text pointer': hover }"
                            >mdi-pencil-circle-outline
                            </v-icon>
                          </v-btn>
                        </v-hover>
                      </div>
                      <div
                      class="hidden"
                      :ref="`controlBtns-${referee.id}`"
                      >
                        <v-hover
                          v-slot="{ hover }"
                        >
                        <v-btn
                        text
                        @click="updateEduc(referee)"
                        >
                          <v-icon
                          size="25"
                          :class="{ 'on-hover blue--text pointer': hover }"
                          >mdi-content-save
                          </v-icon>
                        </v-btn>
                        </v-hover>
                        <v-hover
                          v-slot="{ hover }"
                        >
                        <v-btn
                        text
                        @click="deleteRef(referee)"
                        >
                          <v-icon
                          size="25"
                          :class="{ 'on-hover red--text pointer': hover }"
                          >mdi-delete-circle-outline
                          </v-icon>
                        </v-btn>
                        </v-hover>
                        <v-hover
                          v-slot="{ hover }"
                        >
                        <v-btn
                        text
                        @click="modifyCancel(referee)"
                        >
                          <v-icon
                          size="25"
                          :class="{ 'on-hover grey--text pointer': hover }"
                          >mdi-close-circle-outline
                          </v-icon>
                        </v-btn>
                        </v-hover>
                      </div>
                    </v-row>
                    <v-row class="hidden" :ref="`section-${referee.id}`">
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <p class="body-1 mb-0">First Name</p>
                      <v-text-field
                        v-model="referee.name"
                        tabindex="10"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>
                    </v-col>
                    
                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <p class="body-1 mb-0">Last Name</p>
                      <v-text-field
                        v-model="referee.lastName"
                        tabindex="10"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <p class="body-1 mb-0">Position</p>
                      <v-text-field
                        v-model="referee.position"
                        tabindex="10"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <p class="body-1 mb-0">Organisation</p>
                      <v-text-field
                        v-model="referee.organisation"
                        tabindex="10"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <p class="body-1 mb-0">Phone</p>
                      <v-text-field
                        v-model="referee.phone"
                        tabindex="10"
                        :rules="[rules.required]"
                        outlined
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" md="6" class="pt-0 pb-0">
                      <p class="body-1 mb-0">Email</p>
                      <v-text-field
                        v-model="referee.email"
                        :rules="[rules.required]"
                        tabindex="10"
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  </div>
                </div>

                <div>
                  <div class="d-flex flex-row">
                    <v-col cols="12" class="pl-0 pr-0">
                      <v-btn
                        @click="addRow('add')"
                        color="success"
                        class="mx-2"
                        depressed
                        x-small
                        fab
                      >
                        <v-icon dark>mdi-plus</v-icon>
                      </v-btn>
                      <span class="success--text font-weight-bold mt-2"
                        >Add Referee</span
                      >
                    </v-col>
                  </div>

                  <div class="container-education">
                    <v-form ref="form">
                    <v-row
                      class="eduction pa-0"
                      v-for="(referee, index) in proRef"
                      :key="index"
                      dense
                    >
                      <v-col cols="12">
                        <div
                          v-if="index > 0"
                          class="d-flex justify-end mr-n1"
                        >
                          <span class="text-p font-weight-bold mt-2"
                            >Remove Referee</span
                          >
                          <v-btn
                            class="mx-2 remove-btn"
                            fab
                            dark
                            x-small
                            color="dark"
                            @click="removeRow(index, 'add')"
                          >
                            <v-icon dark> mdi-minus </v-icon>
                          </v-btn>
                        </div>
                      </v-col>

                    
                      <v-col cols="12" md="6">
                        <p :class="$text.label">First Name*</p>
                        <v-text-field
                          v-model="referee.name"
                          tabindex="10"
                          :rules="[rules.required]"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12" md="6">
                        <p :class="$text.label">Last Name*</p>
                        <v-text-field
                          v-model="referee.lastName"
                          tabindex="10"
                          :rules="[rules.required]"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <p :class="$text.label">Position*</p>
                        <v-text-field
                          v-model="referee.position"
                          tabindex="10"
                          :rules="[rules.required]"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <p :class="$text.label">Organisation*</p>
                        <v-text-field
                          v-model="referee.organisation"
                          tabindex="10"
                          :rules="[rules.required]"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <p :class="$text.label">Phone*</p>
                        <v-text-field
                          v-model="referee.phone"
                          tabindex="10"
                          :rules="[rules.required, rules.phone]"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" md="6">
                        <p :class="$text.label">Email*</p>
                        <v-text-field
                          v-model="referee.email"
                          tabindex="10"
                          :rules="[rules.required, rules.email]"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      
                      <v-col cols="12">
                        <p :class="$text.label">Briefly describe your relationship to the referee*</p>
                        <v-text-field
                          v-model="referee.relation"
                          placeholder="e.g. Manager on recent 6 month contract"
                          :rules="[rules.required]"
                          tabindex="10"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                    </v-row>
                    </v-form>
                  </div>
                  <div class="d-flex justify-end gap-10 mt-3">
                    <v-btn
                      color="accent gradient"
                      @click="save()"
                      :loading="status.adding"
                      dark
                    >
                      Save
                    </v-btn>
                    
                    <v-btn 
                      :loading="status.adding" 
                      @click="cancel()"
                      depressed
                    >
                      Cancel
                    </v-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import rules from "@/rules"
export default {
  /* -------------------------------------------------------------------------- */
  /*                                    PROPS                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    profile: [Object, Array],
  },

  data() {
    return {
      rules,
      proRef: [
        {
          name: '',
          position: '',
          organisation: '',
          phone: '',
          email: '',
        },
      ],
    };
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      proReferees: state => state.profile.referees,
      status: (state) => state.profile.status,
    }),

  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('profile', [
      'deleteReferees',
      'updateReferees',
      'addReferees',
    ]),

    addRow(type) {
      if (type == "add") {
        this.proRef.push({
          name: '',
          position: '',
          organisation: '',
          phone: '',
          email: '',
        });
      }
    },

    removeRow(index, type) {
      if (type == "add") {
        if (this.proRef.length > 1) {
          this.proRef.splice(index, 1)
        }
      } else if (type == "update") {
        if (this.proRef.length > 1) {
          this.proRef.splice(index, 1)
        }
      }
    },

    modifyReferee(referee) {
      let refEduc = this.$refs[`section-${referee.id}`]
      
      refEduc[0].classList.forEach(function(item) {
        if(item == 'hidden') {
          refEduc[0].classList.value = 'row'
        }
      })

      let btns = this.$refs[`controlBtns-${referee.id}`]
      
      btns[0].classList.forEach(function(item) {
        
        if(item == 'hidden') {
          btns[0].classList.value = ''
        }
      })
      
      let editBtn = this.$refs[`edit-${referee.id}`]
      editBtn[0].classList.add('hidden')
    },

    modifyCancel(referee) {
      let refEduc = this.$refs[`section-${referee.id}`]
      
      refEduc[0].classList.add('hidden')

      let btns = this.$refs[`controlBtns-${referee.id}`]
      
      btns[0].classList.add('hidden')
      
      let editBtn = this.$refs[`edit-${referee.id}`]
      
      editBtn[0].classList.forEach(function(item) {
        if(item == 'hidden') {
          editBtn[0].classList.value = ''
        }
      })
      
      
    },

    deleteRef(referee) {
      this.deleteReferees(referee)
      this.modifyCancel(referee)
    },

    updateEduc(referee) {
      this.updateReferees(referee)
      this.modifyCancel(referee)
    },

    save() {
      if(this.$refs.form.validate()){
        Promise.all([this.addReferees(this.proRef)])
        .then(() => {
          this.cancel()
        })
      }

    },

    cancel() {
      this.proRef = [{
        name: '',
          position: '',
          organisation: '',
          phone: '',
          email: '',
      }]
      this.$refs.form.reset()
    },

  },
}
</script>