<template>
  <div class="flex-grow-1">
    <v-row dense>
      <v-col cols="12">
        <v-form ref="referees">
          <v-expansion-panels class="mb-7" flat>
            <v-expansion-panel class="shadow-lg">
              <v-expansion-panel-header>
                <div class="d-flex flex-column">
                  <span class="text-h6 primary--text font-weight-black text-capitalize">
                    Recent Teaching Experience (optional)
                  </span>
                </div>
                <template v-slot:actions>
                  <v-icon color="icon-chev" fab solo>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mb-5"></v-divider>
                <div v-if="_experience.length">
                  <div
                    class="d-flex gap-10 align-start mb-5"
                    v-for="(item) in _experience"
                    :key="item.id"
                  >
                    <div class="body-1 font-weight-medium flex-grow-1">
                      <div class="font-weight-bold">{{ item.position }}</div>
                      <div><strong>{{ item.schoolName }},</strong></div>
                      <div>{{ item.start | monthYear }} to {{ item.end | monthYear }}</div>
                      <div>{{ item.region }}</div>
                      <div class="mt-3">
                        {{ item.overview }}
                      </div>
                    </div>
                    <div class="d-flex align-center">
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          @click="modifyReferee(item)"
                          small
                          text
                          icon
                        >
                          <v-icon
                            size="25"
                            :class="{ 'on-hover success--text pointer': hover }"
                          >
                            mdi-pencil-circle-outline
                          </v-icon>
                        </v-btn>
                      </v-hover>
                        
                      <v-hover v-slot="{ hover }">
                        <v-btn
                          @click="showDeleteDialog(item)"
                          small text icon
                        >
                          <v-icon
                            :class="{ 'on-hover red--text pointer': hover }"
                            size="25"
                          >
                            mdi-delete-circle-outline
                          </v-icon>
                        </v-btn>
                      </v-hover>
                    </div>
                  </div>
                </div>

                <div>
                  <v-alert :value="!!error" type="error" border="left" text dense>
                    {{ error }}
                  </v-alert>

                  <div class="container-education">
                    <v-form ref="form">
                      <v-row dense>
                        <v-col cols="12" md="6">
                          <div :class="$text.label">School / Employer*</div>
                           <v-text-field
                            v-model="data.schoolName"
                            :rules="[rules.required]"
                            hide-details
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <div :class="$text.label">Position Held*</div>
                          <v-text-field
                            v-model="data.position"
                            :rules="[rules.required]"
                            hide-details
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="6">
                          <div :class="$text.label">Started*</div>
                          <v-menu
                            ref="menuStart"
                            v-model="menuStart"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            min-width="290px"
                            offset-y
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="formatDate(data.start)"
                                :rules="[rules.required]"
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                readonly
                                outlined
                                dense
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="data.start"
                              @change="$refs.menuStart.save(data.start)"
                              :max="maxDate"
                              type="month"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>
                        
                        <v-col cols="12" md="6">
                          <div :class="$text.label">Finished*</div>
                          <v-menu
                            ref="menuEnd"
                            v-model="menuEnd"
                            :close-on-content-click="false"
                            transition="scale-transition"
                            offset-y
                            min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                :value="formatDate(data.end)"
                                :rules="[rules.required]"
                                v-bind="attrs"
                                v-on="on"
                                hide-details
                                readonly
                                outlined
                                dense
                              ></v-text-field>
                            </template>
                            <v-date-picker
                              v-model="data.end"
                              @change="$refs.menuEnd.save(data.end); menuEnd = false;"
                              :min="minDate"
                              :max="maxDate"
                              type="month"
                            ></v-date-picker>
                          </v-menu>
                        </v-col>

                        <v-col cols="12">
                          <div :class="$text.label">Region* <span class="caption">(e.g. Suburb/Town/City/State/Country)</span></div>
                          <v-text-field
                            v-model="data.region"
                            :rules="[rules.required]"
                            :max="maxDate"
                            hide-details
                            outlined
                            dense
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" class="pt-0">
                          <div :class="$text.label">Brief overview of the role*</div>
                          <v-textarea
                            v-model="data.overview"
                            :rules="[(value) => !!value && value.split(' ').length <= 100 || 'Input should not exceed 100 words']"
                            hide-details
                            auto-grow
                            outlined
                            rows="3"
                          ></v-textarea>
                        </v-col>

                      </v-row>
                    </v-form>
                  </div>
                  <div class="d-flex justify-end gap-10 mt-3">
                    <v-btn
                      v-if="data.id"
                      @click="updateExp()"
                      :loading="status.updating"
                      color="accent gradient"
                      dark
                    >
                      Update
                    </v-btn>
                    
                    <v-btn
                      v-if="!data.id"
                      @click="saveExp()"
                      :loading="status.updating"
                      color="accent gradient"
                      dark
                    >
                      Add
                    </v-btn>

                    <v-btn 
                      @click="cancel()"
                      :disabled="status.updating" 
                      depressed
                    >
                      Cancel
                    </v-btn>
                  </div>
                </div>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-col>
    </v-row>

    <!-- CONFIRM DELETE -->
    <confirm-delete
      :show="deleteDialog"
      message="Are you sure you want to delete this experience?"
      :deleting="status.deleting"
      @cancel="closeDeleteDialog()"
      @confirmed="deleteConfirmed()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import rules from "@/rules"
import moment from 'moment'

export default {
  /* -------------------------------------------------------------------------- */
  /*                                    PROPS                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    profile: [Object, Array],
  },

  data() {
    return {
      rules,
      data: {},
      menuStart: false,
      menuEnd: false,
      error: null,
      deleteDialog: false,
      toDelete: null,
      experience: [
        {
          schoolName: '',
          position: '',
          dateRange: '',
          region: '',
          overview: '',
        },
      ],
    };
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      _experience: state => state.profile.experience,
      status: (state) => state.profile.status,
    }),

    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1970}, (value, index) => 1971 + index).reverse()
    },

    minDate: function () {
      return this.data.start ? moment(this.data.start).format('YYYY-MM') : moment().format('YYYY-MM')
    },
    
    maxDate: function () {
      return moment().format('YYYY-MM')
    },
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('profile', [
      'deleteExperience',
      'updateExperience',
      'addExperience',
    ]),

    addRow(type) {
      if (type == "add") {
        this.experience.push({
          schoolName: '',
          position: '',
          dateRange: '',
          region: '',
          overview: '',
        });
      }
    },

    removeRow(index, type) {
      if (type == "add") {
        if (this.experience.length > 1) {
          this.experience.splice(index, 1)
        }
      } else if (type == "update") {
        if (this.experience.length > 1) {
          this.experience.splice(index, 1)
        }
      }
    },

    modifyReferee(referee) {
      this.data = Object.assign({}, referee)
    },

    modifyCancel() {
      this.data = {}
      this.$refs.form.reset()
    },

    deleteExp(data) {
      this.deleteExperience(data)
    },

    updateExp() {
      this.error = null

      if(this.$refs.form.validate()){
        Promise.all([this.updateExperience(this.data)])
        .then(() => {
          this.modifyCancel()
        })
      }
      else {
        this.error = 'All fields are required'
      }
    },

    saveExp() {
      this.error = null

      if (this.$refs.form.validate()) {
        Promise.all([this.addExperience(this.data)])
        .then(() => {
          this.modifyCancel()
        })
      }
      else {
        this.error = 'All fields are required'
      }
    },

    cancel() {
      this.data = {}
      this.$refs.form.resetValidation()
    },

    showDeleteDialog(item) {
      this.toDelete = item
      this.deleteDialog = true
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteDialog = false
    },

    deleteConfirmed() {
      Promise.all([this.deleteExperience(this.toDelete)])
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    formatDate(date) {
      let year = null
      let month = null
      
      if (date) {
        [year, month] = date.split('-')
      }

      return year && month ? `${year}/${month}` : null
    }
  },
}
</script>