<template>
  <div class="flex-grow-1">
    <v-row>
      <v-col cols="12" class="mt-3 pb-0">
        <v-form ref="referees">
          <v-expansion-panels flat>
            <v-expansion-panel class="shadow-lg">
              <v-expansion-panel-header>
                <div class="d-flex flex-column">
                  <span class="text-h6 primary--text font-weight-black text-capitalize">
                    Skill Set Areas*
                  </span>
                  <p>Select all relevant subject areas, positions and student levels you want to work in.</p>
                </div>
                <template v-slot:actions>
                  <v-icon color="icon-chev" fab solo>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mb-5"></v-divider>

                <v-form ref="skillForm">
                  <div>
                    <div class="container-education">
                      <v-alert :value="error" type="error" border="left" dense text>
                        All fields are required.
                      </v-alert>

                      <v-row class="eduction" dense>

                        <v-col cols="12">
                          <div :class="$text.label">Areas Trained / Qualified In* <small>(Up to 20 maximum)</small></div>
                          <SkillSetsField :value.sync="profile.skillset" hideDetails dense multiple required :tabindex="1"/>
                        </v-col>
                        
                        <v-col cols="12">
                          <div :class="$text.label">Areas Willing and Able to Supervise</div>
                          <p class="caption font-weight-medium mb-1">Select the areas OUTSIDE of your teaching specialisation that you want to supervise. (Up to 20 maximum)</p>
                          <SkillSetsField :value.sync="profile.willingSkills" hideDetails dense multiple filter :tabindex="1"/>
                        </v-col>

                      </v-row>
                    </div>
                  </div>
                  
                  <div class="d-flex justify-end gap-10 mt-3">
                    <v-btn
                      @click="submitSkills()"
                      :loading="status.adding"
                      color="accent gradient"
                      dark
                    >
                      Save
                    </v-btn>

                    <v-btn 
                      @click="cancel()"
                      :loading="status.adding" 
                      depressed
                    >
                      Cancel
                    </v-btn>
                    
                  </div>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState } from "vuex"
import rules from "@/rules"
import { mapActions } from 'vuex'
export default {
  /* -------------------------------------------------------------------------- */
  /*                                    PROPS                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    profile: [Object, Array],
  },

  data() {
    return {
      rules,
      error: false,
      proRef: [
        {
          schoolName: '',
          fieldOfStudy: '',
          yearStart: '',
          yearEnd: '',
          introduction: '',
          achievements: '',
        },
      ],
    };
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      proReferees: state => state.profile.referees,
      status: (state) => state.profile.status,
    }),

    years() {
      const year = new Date().getFullYear()
      return Array.from(
        { length: year - 1970 },
        (value, index) => 1971 + index
      ).reverse()
    },
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('profile', [
      'updateProfileInfo',
    ]),

    addEducationRow(type) {
      if (type == "add") {
        this.proRef.push({
          schoolName: '',
          fieldOfStudy: '',
          yearStart: '',
          yearEnd: '',
          introduction: '',
          achievements: '',
        });
      }
    },

    removeEducationRow(index, type) {
      if (type == "add") {
        if (this.proRef.length > 1) {
          this.proRef.splice(index, 1)
        }
      } else if (type == "update") {
        if (this.proRef.length > 1) {
          this.proRef.splice(index, 1)
        }
      }
    },

    submitSkills() {
      this.error = false

      if (this.$refs.skillForm.validate()) {
        this.updateProfileInfo(this.profile)
      }
      else {
        this.error = true
      }
    }
  },
}
</script>