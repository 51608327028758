<template>
  <div v-if="user" class="flex-grow-1 pb-12">
    <UserTitle hideBack>
      <template v-slot:actions>
          <div class="d-flex align-center flex-grow-1 justify-space-between">
            <h2 class="text-h4 font-weight-black">My Profile</h2>
            <v-btn 
              v-if="user"
              :to="{ name: 'TalentProfile', params: { id: user.userid } }"
              color="primary"
            >View Public Profile</v-btn>
          </div>
      </template>
    </UserTitle>

    <div class="full-width">
      <v-form ref="basicData" v-if="user && user.role=='jobseeker'">
        <v-col cols="12">
          <span class="text-h5 primary--text font-weight-black mb-5">Bio and Contact Details*</span>
          <v-card class="mt-4 shadow-lg">
            <v-card-text>
              <v-form ref="bio">  
                <v-row v-if="user">
                  <v-col cols="12" class="py-0">
                    <div class="d-flex align-start gap-24" :class="{ 'flex-wrap' : $vuetify.breakpoint.xs }">
                      <div class="mb-2 mb-sm-6">
                        <UserPhoto
                          :id="user.userid"
                          :size="150"
                          tile
                          photoSize="small"
                          editable
                        />
                      </div>
                      <div class="mb-0 flex-grow-1">
                        <div :class="$text.label">Your Headline</div>
                        <p class="caption font-weight-medium">1-2 sentences for your bio that describes (and sells) you to hiring schools</p>
                        <v-textarea
                          v-model="profile.aboutMe"
                          tabindex="1"
                          outlined
                          rows="7"
                        >
                        </v-textarea>
                      </div>
                    </div>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-row dense>
                      <v-col cols="12" md="6">
                        <div :class="$text.label">First Name*</div>
                        <v-text-field
                          v-model="user.firstName"
                          :rules="[rules.required]"
                          hide-details
                          tabindex="3"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col cols="12" md="6">
                        <div :class="$text.label">Last Name*</div>
                        <v-text-field
                          v-model="user.lastName"
                          :rules="[rules.required]"
                          hide-details
                          tabindex="4"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <div :class="$text.label">Email*</div>
                        <v-text-field
                          v-model="user.email"
                          :rules="[rules.required, rules.email]"
                          :hint="getSignupMethod() !== 'Email and Password' ? 'Email cannot be updated if your account was created using Google and Facebook.' : ''"
                          :readonly="getSignupMethod() !== 'Email and Password' || !status.accountConfirmed"
                          @click="confirmEmail()"
                          hide-details
                          tabindex="7"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <div :class="$text.label">Phone</div>
                        <v-text-field
                          v-model="profile.phone"
                          :rules="[rules.phone]"
                          value="phone"
                          hide-details
                          tabindex="5"
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col>
                        <div :class="$text.label">What teaching roles are you wanting?*</div>
                        <div class="caption mb-1 mt-n1">Select all the role types you are qualified and open to work in</div>
                        <v-autocomplete
                          v-model="profile.position"
                          tabindex="6"
                          :items="orderBy(positions, 'order', 'asc')"
                          :multiple="positions.length == 1? false : true"
                          item-text="name"
                          item-value="name"
                          :rules="[rules.required]"
                          deletable-chips
                          hide-details
                          small-chips
                          full-width
                          outlined
                          chips
                          dense
                        >
                        </v-autocomplete>
                      </v-col>
                    </v-row>
                    <div class="mt-1">
                      <div :class="$text.label">Enter your suburb*</div>
                      <div class="mb-1 mt-1">
                        <vue-google-autocomplete
                          id="school-address"
                          ref="toAddress"
                          classname="address-field"
                          v-on:placechanged="getAddress"
                          placeholder="Your Suburb"
                          :fields="['address_components', 'geometry']"
                          types="(regions)"
                          country="au"
                        >
                        </vue-google-autocomplete>

                        <div v-show="addressError" class="error--text">
                          This field is required
                        </div>
                      </div>

                      <div v-if="user.address">
                        <v-row dense>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              :value="user.address.locality || ''"
                              label="Suburb"
                              hide-details
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              :value="user.address.administrative_area_level_1 || ''"
                              label="State"
                              hide-details
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="4">
                            <v-text-field
                              :value="user.address.postal_code || ''"
                              label="Postal Code"
                              hide-details
                              readonly
                              outlined
                              dense
                            ></v-text-field>
                          </v-col>
                        </v-row>
                      </div>
                    </div>
                    <div class="mb-1">
                      <div :class="$text.label">LinkedIn</div>
                      <v-text-field
                        v-model="profile.linkedin"
                        :rules="[rules.url]"
                        hide-details
                        tabindex="8"
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                    <div>
                      <div :class="$text.label">Your Video Introduction</div>
                      <div class="font-weight-medium caption mb-1">Want to stand out from the crowd? Create a short (1-2min) video to introduce yourself to schools. Check out free tools like www.vimeo.com to do this easily.</div>
                      <v-text-field
                        v-model="profile.videoPresentation"
                        tabindex="2"
                        hint="Youtube, Vimeo, Dailymotion, Coub"
                        :rules="[rules.url]"
                        hide-details
                        outlined
                        dense
                      ></v-text-field>
                    </div>
                  </v-col>
                  <v-col cols="12" class="mt-5 pa-0">
                    <div class="d-flex justify-end gap-10">
                      <v-btn
                        color="accent gradient"
                        @click="saveBio()"
                        :loading="status.updating"
                        dark
                      >
                        Save
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-form>  
            </v-card-text>
          </v-card>
        </v-col>

        <!-- TEACHING SKILLS & EXPERIENCE -->
        <div class="mt-5">
          <v-expansion-panels flat>
            <v-expansion-panel class="shadow-lg">
              <v-expansion-panel-header>
                <div class="d-flex flex-column">
                  <span class="text-h6 primary--text font-weight-black text-capitalize">
                    Teaching Skills & Experience*
                  </span>
                </div>
                <template v-slot:actions>
                  <v-icon color="icon-chev" fab solo>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card class="mt-4" flat>
                  <v-card-text class="pa-5">
                    <div class="text-h6 primary--text font-weight-black mb-5">Teaching Overview</div>

                    <v-divider class="mb-5"></v-divider>

                    <v-row dense>
                      <v-col cols="12">
                        <v-form ref="experience">  
                        <div :class="$text.label">Number of Years Teaching*</div>
                        
                        <v-select
                          v-model="profile.teachingLvl"
                          :rules="[rules.required]"
                          :items="teachingLvl"
                          hide-details
                          class="mb-1"
                          outlined
                          dense
                        ></v-select>

                        <div :class="$text.label">Schools Worked At</div>
                        <div class="mb-1 caption font-weight-medium">Select all the schools you've worked at before and want to work at again, so when it comes time to hiring, these schools can easily find you.</div>
                        
                        <SchoolField :value.sync="profile.schoolWorkedAt" hideDetails dense multiple/>
                      </v-form>
                      </v-col>
                      <v-col cols="12">
                        <div class="d-flex justify-end gap-10 mt-3">
                          <v-btn
                            color="accent gradient"
                            @click="save()"
                            :loading="profileStatus.updating || profileStatus.adding"
                            dark
                          >
                            Save
                          </v-btn>
                        </div>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </div>

        <div class="mb-3">
          <Skillset v-if="profile" :profile="profile" />
        </div>
        
        <div>
          <Qualification v-if="profile" :profile="profile" />
        </div>

        <div>
          <Resume v-if="profile" :profile="profile" />
        </div>

        <div>
          <ProfessionalReferees  v-if="profile" :profile="profile" />
        </div>

        <div class="d-none">
          <RecentExperience  v-if="profile" :profile="profile" />
        </div>

        <div>
          <Credentials v-if="profile" :profile="profile" />
        </div>
      </v-form>
    </div>

    <ConfirmPassword/>

    <!-- <div>
      <v-checkbox 
        v-model="user.declaration" 
        @change="updateDeclaration()"
        class="align-start" 
      >
        <template v-slot:label>
          <div>
            <p class="font-weight-bold">Declaration: </p>
            <p>I certify to the best of my knowledge and belief the information given in this profile is true and accurate. I understand that if the information is false or misleading it may disqualify me from any appointments with employers and/or could lead to a disciplinary action or dismissal.  </p>
            <p>I acknowledge that where this profile information is submitted electronically without signature, electronic receipt of my saved profile by TeacherFinder will be deemed equivalent to submission of a signed version and will constitute confirmation of this declaration. </p>
          </div>
        </template>
      </v-checkbox>
    </div> -->

    <div class="text-right">
      <v-btn 
        v-if="user"
        :to="{ name: 'TalentProfile', params: { id: user.userid } }"
        color="primary"
      >View Public Profile</v-btn>
    </div>
  </div>
</template>

<script>

import rules from '@/rules'
import firebase from 'firebase'
import orderBy from 'lodash/orderBy'
import { mapState, mapActions } from 'vuex'
import locations from '@/assets/json/au_locations.json'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

import Resume from '@/components/user/profile/Resume'
import Skillset from '@/components/user/profile/Skillset'
import Credentials from '@/components/user/profile/Credentials'
import Qualification from '@/components/user/profile/Qualification'
import RecentExperience from '@/components/user/profile/RecentExperience'
import ProfessionalReferees from '@/components/user/profile/ProfessionalReferees'



export default {
  name: 'AccountSettings',

  metaInfo: {
    title: 'My Profile'
  },

  components: {
    Qualification,
    Credentials,
    VueGoogleAutocomplete,
    ProfessionalReferees,
    Skillset,
    RecentExperience,
    Resume
  },

  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      orderBy,
      profileData: [],
      auLocation: locations,
      addressError: false,
      label: 'heading-font font-weight-bold primary--text mb-1',
      educations: [{
        schoolName: '',
        fieldOfStudy: '',
        yearStart: '',
        yearEnd: '',
        introduction: '',
        achievements: '',
      }],
      teachingLvl: [
        'Graduate',
        '2-4 years',
        '4-10 years',
        '10+ years'
      ],
    }
  },

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      user: state => state.user.user,
      status: state => state.user.status,
      files: state => state.profile.files,
      password: state => state.user.password,
      profile: state => state.profile.profile,
      positions: state => state.position.data,
      referees: state => state.profile.referees,
      education: state => state.profile.education,
      profileStatus: state => state.profile.status,
      hasProfile: state => state.profile.hasProfile,
      experience: state => state.profile.experience,
      positionStatus: state => state.position.status,
      attachments: state => state.profile.attachments,
    }),

    currentUser: function () {
      if (firebase.auth().currentUser) {
        return firebase.auth().currentUser
      }
      else {
        return {}
      }
    },

    years () {
      const year = new Date().getFullYear()
      return Array.from({length: year - 1970}, (value, index) => 1971 + index).reverse()
    },

  },

  watch: {
    'profile': function (profile) {
      if(profile.position) {
        this.getPositionList()
      }
    },
  },


  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/
  methods: {
    ...mapActions('user', [
      'updateProfile',
      'updatePassword',
      'updateDeclaration'
    ]),

    ...mapActions('position', [
      'getPositions',
    ]),

    ...mapActions('profile', [
      'storeProfileDetails',
      'updateProfileInfo',
      'deleteAttachment',
      'getProfile',
      'uploadFile',
    ]),

    limiter(e) {
      if (e.length > 25) {
        this.$refs.ableToSuperviseUpdate._props.errorMessages.push('Limit of 25 items in skillsets')
        e.pop()
      }
      else {
        this.$refs.ableToSuperviseUpdate._props.errorMessages.forEach(function(item, index, object) {
          object.splice(index, 1)
        })
      }
    },

    getSignupMethod() {
      if (this.currentUser.providerData[0].providerId == 'google.com') return 'Google'
      else if (this.currentUser.providerData[0].providerId == 'facebook.com') return 'Facebook'
      else return 'Email and Password'
    },

    validateProfile() {
      if (this.$refs.accountForm.validate()) {
        this.updateProfile()
      }
    },

    confirmed() {
      this.confirmedPassword = true
    },

    confirmEmail() {
      if (this.getSignupMethod() == 'Email and Password' && !this.status.accountConfirmed) {
        this.$store.commit('user/setShowConfirmPassword', true)
      }
    },

    validatePassword() {
      if (this.$refs.passwordForm.validate()) {
        Promise.all([this.updatePassword()])
        .then(() => {
          this.$refs.passwordForm.reset()
        })
      }
    },

    required(value) {
      if (value instanceof Array && value.length == 0) {
        return 'Required.';
      }
      return !!value || 'Required.';
    },

    clearPasswordForm() {
      this.$refs.passwordForm.reset()
    },

    validateProfileInfo(type) {
      if (this.$refs.profileInformation.validate()) {
        if(type == 'add') {
          Promise.all([
            this.storeProfileDetails({
              profile: this.profile, 
              educations: this.educations, 
              recentExperience: this.experience, 
              referees: this.referees, 
              type: 'add'
            })
          ])
          .then(() => {
            this.getProfile()
          })
        }
        else if(type == 'update') {
          Promise.all([this.storeProfileDetails({
            profile: this.profile, 
            education: this.education, 
            recentExperience: this.experience, 
            referees: this.referees, 
            type: 'update'
          })])
          .then(() => {
            this.getProfile()
          })
        }
      }
    },

    cancel() {
      this.$refs.experience.reset()
    },

    save() {
      if(this.$refs.experience.validate()){
        this.updateProfileInfo(this.profile)
      }
    },

    cancelBio() {
      this.$refs.experience.reset()
    },
    
    saveBio() {
      this.addressError = !(this.user.address)

      if (this.$refs.bio.validate() && !this.addressError) {
        this.updateProfile(this.user)
        this.updateProfileInfo(this.profile)
      }
    },

    getAddress(addressData) {
      this.$store.commit('user/setUserAddress', addressData)
      this.$refs.toAddress.$refs.autocomplete.value = ''
    },

    getPositionList() {
      if (!this.positionStatus.firstLoad) this.getPositions()
    },

  },

  /*------------------------------------------------------------------------------
   * MOUNTED
   *----------------------------------------------------------------------------*/
  mounted() {
    this.getPositionList()
  },

  /*------------------------------------------------------------------------------
   * BEFORE ON ROUTE LEAVE  
   *----------------------------------------------------------------------------*/
  // beforeRouteLeave: function (to, from, next) {
  //   if (!this.user.declaration) {
  //     let answer = window.confirm('You have not agreed with the declaration yet. Your profile will be hidden from the search page. Do you still want to leave this page?')
  //     if (answer) next()
  //   }
  //   else if (!this.$store.getters['user/completeProfile']) {
  //     let answer = window.confirm('You have not completed all mandatory fields yet. Your profile will not be active until these fields are completed and details may be lost. Do you still want to leave this page?')
  //     if (answer) next()
  //   }
  //   else {
  //     next()
  //   }
  // }
}

</script>


<style lang="scss">
  .eduction, .container-education{
    width: 100%
  }

  .align-start {
    .v-input__slot {
      align-items: start !important;
    }
  }
</style>
