<template>
  <div class="flex-grow-1">
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
          <v-expansion-panels class="mb-7" flat>
            <v-expansion-panel class="shadow-lg">
              <v-expansion-panel-header>
                <div class="d-flex flex-column">
                  <span class="text-h6 primary--text font-weight-black text-capitalize">
                    CV / Resume
                  </span>
                </div>
                <template v-slot:actions>
                  <v-icon color="icon-chev" fab solo>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mb-5"></v-divider>

                <v-chip v-if="profile.resume">
                  <span>{{ profile.resume | fileName }}</span>

                  <v-btn @click="$downloadFile('attachments', profile.resume)" class="ml-2" x-small icon>
                    <v-icon small>mdi-download</v-icon>
                  </v-btn>
                </v-chip>
                
                <v-form ref="fileForm" class="mt-3">
                  <v-file-input
                    v-model="resume"
                    prepend-inner-icon="mdi-attachment"
                    :rules="[rules.required]"
                    ref="attachments"
                    prepend-icon=""
                    outlined
                    dense
                  ></v-file-input>

                  <div class="d-flex justify-end">
                    <v-btn
                      @click="upload()"
                      :loading="status.uploading"
                      color="accent gradient"
                      class="ml-4"
                      dark
                    >
                      Upload
                    </v-btn>
                  </div>
                </v-form>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import rules from '@/rules'
import { mapState, mapActions } from 'vuex'

export default {
  /*------------------------------------------------------------------------------
   * DATA
   *----------------------------------------------------------------------------*/
  data() {
    return {
      rules,
      resume: null,
    }
  },

  /*------------------------------------------------------------------------------
   * PROPS
   *----------------------------------------------------------------------------*/
  props: ['profile'],

  /*------------------------------------------------------------------------------
   * COMPUTED
   *----------------------------------------------------------------------------*/
  computed: {
    ...mapState({
      status: (state) => state.profile.status
    })
  },

  /*------------------------------------------------------------------------------
   * METHODS
   *----------------------------------------------------------------------------*/  
  methods: {
    ...mapActions('profile', ['uploadResume']),

    upload() {
      if (this.$refs.fileForm.validate()) {
        Promise.all([this.uploadResume(this.resume)])
        .then(() => {
          this.$refs.fileForm.reset()
        })
      }
    }
  }
}
</script>