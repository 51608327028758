<template>
  <div class="flex-grow-1">
    <v-row>
      <v-col cols="12" class="pb-0">
        <v-expansion-panels class="mb-7" flat>
          <v-expansion-panel class="shadow-lg">
            <v-expansion-panel-header>
              <div class="d-flex flex-column">
                <span class="text-h6 primary--text font-weight-black text-capitalize">
                  Tertiary / VET Qualifications
                </span>
              </div>
              <template v-slot:actions>
                <v-icon color="icon-chev" fab solo>mdi-chevron-down</v-icon>
              </template>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-divider class="mb-5"></v-divider>
              <div v-if="education.length">
                <v-card :max-width="$vuetify.breakpoint.xs ? '300' : '100%'" class="mb-3" outlined>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr class="heading-font">
                          <th :class="$text.label" style="font-size: 14px">University / College</th>
                          <th :class="$text.label" style="font-size: 14px">Degree / Qualification</th>
                          <th :class="$text.label" style="font-size: 14px">Year</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="educ in education"
                          :key="educ.id"
                        >
                          <td>{{ educ.schoolName }}</td>
                          <td>{{ educ.fieldOfStudy }}</td>
                          <td>{{ educ.yearStart }} - {{ educ.yearEnd }}</td>
                          <td align="right">
                            <div v-show="!data.id">
                              <v-hover
                                v-slot="{ hover }"
                              >
                                <v-btn
                                  @click="modifyEducation(educ)"
                                  small
                                  icon
                                >
                                  <v-icon
                                    :class="{ 'on-hover success--text pointer': hover }"
                                  >mdi-pencil-circle-outline</v-icon>
                                </v-btn>
                              </v-hover>

                              <v-hover
                                v-slot="{ hover }"
                                small icon
                              >
                              <v-btn
                                @click="confirmDelete(educ)"
                                small icon
                              >
                                <v-icon
                                  :class="{ 'on-hover red--text pointer': hover }"
                                >mdi-delete-circle-outline</v-icon>
                              </v-btn>
                              </v-hover>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </div>
              
              <div>
                <div class="align-center d-none">
                  <v-btn
                    class="mx-2"
                    @click="addEducationRow('add')"
                    color="success"
                    depressed
                    x-small
                    fab
                  >
                    <v-icon dark> mdi-plus </v-icon>
                  </v-btn>
                  <a 
                    href="#" @click.prevent="addEducationRow('add')" 
                    class="text-decoration-none underline-on-hover success--text"
                  >
                    Add Qualifications
                  </a>
                </div>

                <div class="container-education">
                  <v-form ref="qualificationForm">
                    <v-row class="eduction pa-0" dense>
                      <v-col cols="12" sm="6">
                        <div :class="$text.label">University / College*</div>
                        <v-text-field
                          v-model="data.schoolName"
                          :rules="[rules.required]"
                          tabindex="8"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <div :class="$text.label">Degree / Qualification*</div>
                        <v-text-field
                          v-model="data.fieldOfStudy"
                          :rules="[rules.required]"
                          tabindex="9"
                          hide-details
                          outlined
                          dense
                        ></v-text-field>
                      </v-col>

                      <v-col cols="12" sm="6">
                         <div :class="$text.label">Year Started*</div>
                          <v-select
                            v-model="data.yearStart"
                            :rules="[rules.required]"
                            :items="yearsStart"
                            tabindex="10"
                            hide-details
                            outlined
                            dense
                          ></v-select>
                      </v-col>

                      <v-col cols="12" sm="6">
                        <div :class="$text.label">Year Completed *</div>
                        <v-select
                          v-model="data.yearEnd"
                          tabindex="11"
                          :rules="[rules.required]"
                          :items="yearEnd"
                          :disabled="!data.yearStart"
                          hide-details
                          outlined
                          dense
                        ></v-select>
                      </v-col>
                      
                    </v-row>
                  </v-form>
                </div>
                <div class="d-flex justify-end gap-5 mt-3">
                  <v-btn
                    v-if="data.id"
                    @click="updateEduc()"
                    :loading="status.updating"
                    color="accent gradient"
                    class="mr-2"
                    dark
                  >
                    Update
                  </v-btn>
                  
                  <v-btn
                    v-if="!data.id"
                      @click="validateEducation()"
                    :loading="status.adding"
                    color="accent gradient"
                    class="mr-2"
                    dark
                  >
                    Save
                  </v-btn>

                  <v-btn 
                    @click="cancel()"
                    :disabled="status.updating" 
                    depressed
                  >
                    Cancel
                  </v-btn>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>

    <!-- CONFIRM DELETE SCHOOL -->
    <confirm-delete
      :show="deleting"
      :message="deleteMessage"
      :deleting="status.deletingEducation" 
      @cancel="closeDeleteDialog()"
      @confirmed="deleteEduc()"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import rules from "@/rules"

export default {
  /* -------------------------------------------------------------------------- */
  /*                                    PROPS                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    profile: [Object, Array],
  },

  data() {
    return {
      rules,
      data: {},
      editing: false,
      deleting: false,
      toDelete: null,
      deleteMessage: null,
      educations: [
        {
          schoolName: '',
          fieldOfStudy: '',
          yearStart: '',
          yearEnd: '',
          introduction: '',
          achievements: '',
        },
      ],
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      education: (state) => state.profile.education,
      status: (state) => state.profile.status,
    }),

    years: function () {
      const year = new Date().getFullYear()
      
      let years = Array.from(
        { length: year - 1970 },  
        (value, index) => 1971 + index
      ).reverse()

      years.unshift('Current')
      return years
    },

    yearsStart: function () {
      let years = Object.assign({}, this.years)
      years = Object.values(years)
      years.splice(0, 1)
      return years
    },

    yearEnd: function() {
      let years = this.years.filter(year => {
        return year >= this.data.yearStart || year == 'Current'
      })

      return years
    }
  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('profile', [
      'deleteEducation',
      'updateEducation',
      'addEducation',
    ]),
    
    addEducationRow(type) {
      if (type == "add") {
        this.educations.push({
          schoolName: '',
          fieldOfStudy: '',
          yearStart: '',
          yearEnd: '',
          introduction: '',
          achievements: '',
        });
      }
    },

    removeEducationRow(index, type) {
      if (type == "add") {
        if (this.educations.length > 1) {
          this.educations.splice(index, 1)
        }
      } else if (type == "update") {
        if (this.education.length > 1) {
          this.education.splice(index, 1)
        }
      }
    },

    modifyEducation(educ) {
      this.data = Object.assign({}, educ)
    },

    modifyCancel(educ) {
      let refEduc = this.$refs[`education-${educ.id}`]
      
      refEduc[0].classList.add('hidden')

      let btns = this.$refs[`controlBtns-${educ.id}`]
      
      btns[0].classList.add('hidden')
      
      let editBtn = this.$refs[`edit-${educ.id}`]
      
      editBtn[0].classList.forEach(function(item) {
        if(item == 'hidden') {
          editBtn[0].classList.value = ''
        }
      })
    },

    confirmDelete(educ) {
      this.toDelete = educ
      this.deleting = true
      this.deleteMessage = `Are you sure you want to delete education "${educ.schoolName}"?`      
    },

    closeDeleteDialog() {
      this.toDelete = null
      this.deleteMessage = null
      this.deleting = false
    },

    deleteEduc() {
      Promise.all([
        this.deleteEducation(this.toDelete)
      ])
      .then(() => {
        this.closeDeleteDialog()
      })
    },

    updateEduc() {
      Promise.all([
        this.updateEducation(this.data)
      ])
      .then(() => {
        this.data = {}
      })
    },

    validateEducation() {
      if(this.$refs.qualificationForm.validate()) {
        Promise.all([
          this.addEducation(this.data)
        ])
        .then(() => {
          this.cancel()
        })
      }
    },

    cancel() {
      this.$refs.qualificationForm.reset()
      this.data = {}
      this.$refs.qualificationForm.resetValidation()
    },
    
  },
}
</script>