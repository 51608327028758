<template>
  <div class="flex-grow-1">
    <v-row>
      <v-col cols="12" class="pt-0 pb-0">
          <v-expansion-panels class="mb-7" flat>
            <v-expansion-panel class="shadow-lg">
              <v-expansion-panel-header>
                <div class="d-flex flex-column">
                  <span class="text-h6 primary--text font-weight-black text-capitalize">
                    Declarations
                  </span>
                </div>
                <template v-slot:actions>
                  <v-icon color="icon-chev" fab solo>mdi-chevron-down</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-divider class="mb-5"></v-divider>
                <p class="body-1 font-weight-medium">
                    Tick which of the following you currently hold and enter specific details below (e.g. your State Registration Number). Please note that schools are likely to ask you to produce evidence of these.
                </p>

                <v-file-input
                  @change="filesSelected($event)"
                  ref="attachments"
                  class="d-none"
                  multiple
                ></v-file-input>

              <v-form ref="form">
                <div class="d-flex align-center mb-7 flex-wrap" :class="$vuetify.breakpoint.xs ? 'gap-1' : 'gap-20'">
                  <v-checkbox v-model="profile.teacherRegistration" label="Teacher Registration" hide-details :value="true"></v-checkbox>
                  <v-checkbox v-model="profile.workingWithChildrenCheck" label="Working With Children Check" hide-details :value="true"></v-checkbox>
                  <v-spacer></v-spacer>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-btn @click="$refs.attachments.$refs.input.click()" v-on="on" color="info" fab depressed small>
                        <v-icon>mdi-upload</v-icon>
                      </v-btn>
                    </template>
                    <span>Click here to upload <br>supporting documentation</span>
                  </v-tooltip>
                </div>

                <div v-if="attachments.length" class="my-5 gap-5 d-flex flex-wrap">
                  <v-chip
                    v-for="attachment in attachments"
                    :key="attachment.id"
                  >
                    <span class="mr-2">
                      {{ attachment.file | fileName }}
                    </span>
                    
                    <v-btn @click="$downloadFile('attachments', attachment.file)" class="mr-1" x-small icon>
                      <v-icon small>mdi-download</v-icon>
                    </v-btn>

                    <v-btn @click="deleteAttachment(attachment)" x-small icon>
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </v-chip>
                </div>

                <div v-if="files.length" class="d-flex flex-wrap align-center mt-5 gap-12 pb-5">
                  <v-card
                    v-for="(file, i) in files"
                    :key="i"
                    :loading="true"
                    class="shadow-md"
                  >
                    <file-block
                      :file="file"
                      @remove="$store.commit('message/removeAttachment', i)"
                      plain
                    />
                  </v-card>
                </div>

                <div :class="$text.label">Relevant registration details*:</div>
                <v-textarea
                  tabindex="4"
                  v-model="profile.relevantRegistrationDetails"
                  placeholder="e.g. QCT# 334455, EQ#3344556"
                  height="143"
                  outlined
                >  
                </v-textarea>

                <v-checkbox 
                  v-model="profile.declaration" 
                  class="align-start" 
                >
                  <template v-slot:label>
                    <div>
                      <p class="font-weight-bold">Declaration: </p>
                      <p>I certify to the best of my knowledge and belief the information given in this profile is true and accurate. I understand that if the information is false or misleading it may disqualify me from any appointments with employers and/or could lead to a disciplinary action or dismissal.  </p>
                      <p>I acknowledge that where this profile information is submitted electronically without signature, electronic receipt of my saved profile by TeacherFinder will be deemed equivalent to submission of a signed version and will constitute confirmation of this declaration. </p>
                    </div>
                  </template>
                </v-checkbox>

              </v-form>
                <v-col cols="12" class="pa-0 pb-2">
                  <div class="d-flex justify-end gap-10">
                    <v-btn
                      class="ml-4"
                      color="accent gradient"
                      @click="save()"
                      :loading="status.adding"
                      dark
                    >
                      Save
                    </v-btn>

                    <v-btn 
                      @click="cancel()"
                      :loading="status.adding" 
                      depressed
                    >
                      Cancel
                    </v-btn>
                  </div>
                </v-col>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex"
import FileBlock from '@/views/Message/FileBLock'
import rules from "@/rules"
export default {
  /* -------------------------------------------------------------------------- */
  /*                                    PROPS                                   */
  /* -------------------------------------------------------------------------- */
  props: {
    profile: [Object, Array],
  },

  components: {
    FileBlock
  },

  data() {
    return {
      rules,
      relevantRegistrationDetails: null,
    }
  },



  /* -------------------------------------------------------------------------- */
  /*                                  COMPUTED                                  */
  /* -------------------------------------------------------------------------- */
  computed: {
    ...mapState({
      education: (state) => state.profile.education,
      files: state => state.profile.files,
      attachments: state => state.profile.attachments,
      status: (state) => state.profile.status,
    }),

  },

  /* -------------------------------------------------------------------------- */
  /*                                   METHODS                                  */
  /* -------------------------------------------------------------------------- */
  methods: {
    ...mapActions('profile', [
      'uploadFile',
      'deleteAttachment',
      'updateProfileInfo',
    ]),

    filesSelected(e) {
      e.forEach(file => {
        this.$store.commit('profile/insertFile', file)
        this.uploadFile(file)
      })
    },

    cancel() {
      this.$refs.form.reset()
    },

    save() {
      if(this.$refs.form.validate()){
        this.updateProfileInfo(this.profile)
      }
    },

  },
}
</script>